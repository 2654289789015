<template>
  <div class="card">
    <h2 class="v-center">
      <StarsIcon /> {{ $t("wallet.top_ten") }}
    </h2>
    <hr>
    <Person
      v-for="(person, position) in people"
      :key="position"
      :person="person"
      :position="position+1"
    />
  </div>
</template>

<script>
import StarsIcon from '@/components/icons/Stars.vue';

import Person from './Person.vue';

export default {
  components: {
    StarsIcon,
    Person,
  },
  data() {
    return {
      people: [
        {
          name: 'Толя',
          username: 'fgpkdfsgk',
          avatar: 'http://placekitten.com/100/100',
          income: 133.7,
        },
        {
          name: 'Толя',
          username: 'fgpkdfsgk',
          avatar: 'http://placekitten.com/100/100',
          income: 13.37,
        },
        {
          name: 'Толя',
          username: 'fgpkdfsgk',
          avatar: 'http://placekitten.com/100/100',
          income: 1.337,
        },
        {
          name: 'Толя',
          username: 'fgpkdfsgk',
          avatar: 'http://placekitten.com/100/100',
          income: 0.1337,
        },
        {
          name: 'Толя',
          username: 'fgpkdfsgk',
          avatar: 'http://placekitten.com/100/100',
          income: 1337,
        },
        {
          name: 'Толя',
          username: 'fgpkdfsgk',
          avatar: 'http://placekitten.com/100/100',
          income: 1337,
        },
        {
          name: 'Толя',
          username: 'fgpkdfsgk',
          avatar: 'http://placekitten.com/100/100',
          income: 1337,
        },
        {
          name: 'Толя',
          username: 'fgpkdfsgk',
          avatar: 'http://placekitten.com/100/100',
          income: 1337,
        },
        {
          name: 'Толя',
          username: 'fgpkdfsgk',
          avatar: 'http://placekitten.com/100/100',
          income: 1337,
        },
        {
          name: 'Толя',
          username: 'fgpkdfsgk',
          avatar: 'http://placekitten.com/100/100',
          income: 1337,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";

hr {
  margin-bottom: 24px !important;
}

svg {
  color: $clr-text-trietary;
}
</style>
