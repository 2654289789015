<template>
  <div class="card v-center">
    <CrownIcon /> {{ $t('profile.buy_vip') }}
  </div>
</template>

<script>
import CrownIcon from '@/components/icons/Crown.vue';

export default {
  components: {
    CrownIcon,
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";

svg {
  color: $clr-text-trietary;
}
</style>
