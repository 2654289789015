<template>
  <div class="card">
    <h2 class="v-center">
      <CoinIcon /> {{ $t("wallet.month_income") }}
    </h2>
    <hr>
    <div class="body v-center">
      <h1>
        {{ incomeCaps }}
        <span
          v-if="incomeSmall > 0"
          class="small"
        >
          ,{{ incomeSmall }}
        </span>
        <span class="small">
          €
        </span>
      </h1>
      <div class="v-center right">
        <span>{{ $t("wallet.income_history") }}</span>
        <ChevronIcon />
      </div>
    </div>
  </div>
</template>

<script>
import ChevronIcon from '@/components/icons/Chevron.vue';
import CoinIcon from '@/components/icons/Coin.vue';

export default {
  components: {
    CoinIcon,
    ChevronIcon,
  },
  data() {
    return {
      income: 1.24,
    };
  },
  computed: {
    // Will show a fraction lower if ends with 3 because of computer math error
    // Use strings instead?
    incomeCaps() {
      return Math.floor(this.income);
    },
    incomeSmall() {
      const frac = String(this.income % 1);
      return frac.substring(2, 4);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";

.body {
  justify-content: space-between;
  font: $font-rn-300;

  h1 {
    display: inline-block;

    .small {
      margin-left: -8px;
      font: $font-h-400;
    }
  }

  .right {
    margin-right: -16px;
  }

  svg {
    margin-left: 8px;
    transform: rotateZ(-90deg);
    width: 12px;
    height: 12px;
  }
}

svg {
  color: $clr-text-trietary;
}
</style>
