<template>
  <div class="card v-center">
    <TicketIcon /> {{ $t('profile.my_promocode') }}
  </div>
</template>

<script>
import TicketIcon from '@/components/icons/Ticket.vue';

export default {
  components: {
    TicketIcon,
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";

svg {
  color: $clr-text-trietary;
}
</style>
