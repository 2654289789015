<template>
  <div class="card v-center">
    <CollectionIcon /> {{ $t('wallet.collections') }}
  </div>
</template>

<script>
import CollectionIcon from '@/components/icons/Collection.vue';

export default {
  components: {
    CollectionIcon,
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";

svg {
  color: $clr-text-trietary;
}
</style>
