<template>
  <div class="card v-center">
    <QuestionCircleIcon />
    {{ $t('wallet.how_to_earn_more', { snName: selectedSn.name, snNameRu: selectedSn.nameRu }) }}
  </div>
</template>

<script>
import { mapState } from 'vuex';

import QuestionCircleIcon from '@/components/icons/QuestionCircle.vue';

export default {
  components: {
    QuestionCircleIcon,
  },
  computed: {
    ...mapState({
      selectedSn: (state) => state.api.sn.selectedSn,
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";

.card {
  border: 1px dashed var(--clr-brand);

  svg {
    color: var(--clr-brand);
  }
}
</style>
