<template>
  <div class="wallet">
    <transition-group
      tag="div"
      class="sidebar-wrap"
      name="slide"
    >
      <div
        key="skirt"
        class="skirt"
      />
      <div
        key="card"
        class="card main-card"
      >
        <div class="v-center">
          <WalletIcon />
          <span>{{ $t("wallet.main_wallet") }}</span>
        </div>

        <div class="main v-center">
          <span>
            {{ money }}
          </span>
          <div
            class="icon-circle"
            @click="toggleWalletDropdown"
          >
            <ChevronIcon :class="{ upsidedown: showWalletDropdown }" />
          </div>
        </div>

        <hr>

        <div class="status v-center">
          {{ status }}
          <QuestionCircleIcon />
        </div>
      </div>

      <div
        v-if="showWalletDropdown"
        key="dropdown"
        class="dropdown card"
      >
        <div
          v-for="(coin, index) in coins"
          :key="coin.name"
        >
          <hr v-if="index!==0">
          <div class="coin">
            <span class="name">{{ coin.name }}</span>
            <div class="v-center">
              {{ coin.amount }} {{ coin.abbr }} <MoreIcon />
            </div>
          </div>
        </div>
      </div>

      <SendRecieveBuy key="send-recieve" />
      <BuyVip key="buy-vip" />
      <OperationHistory key="op-hist" />
      <CashOut key="cash-out" />
      <Collections key="collections" />

      <Sidebar
        key="sidebar"
        footer-only
        class="sidebar"
      />
    </transition-group>

    <div class="middle">
      <Invited />
      <Income />
      <Promocode />
      <EarnMore />
    </div>

    <div class="right">
      <TopTen />
    </div>

    <Sidebar
      key="sidebar"
      footer-only
      class="sidebar-mobile"
    />
  </div>
</template>

<script>
import ChevronIcon from '@/components/icons/Chevron.vue';
import MoreIcon from '@/components/icons/More.vue';
import QuestionCircleIcon from '@/components/icons/QuestionCircle.vue';
import WalletIcon from '@/components/icons/Wallet.vue';

import Sidebar from '@/components/TheSidebar/Index.vue';

import BuyVip from './BuyVip.vue';
import CashOut from './CashOut.vue';
import Collections from './Collections.vue';
import EarnMore from './EarnMore.vue';
import Income from './Income.vue';
import Invited from './Invited.vue';
import OperationHistory from './OperationHistory.vue';
import Promocode from './Promocode.vue';
import SendRecieveBuy from './SendRecieveBuy.vue';
import TopTen from './TopTen.vue';

export default {
  name: 'Wallet',
  components: {
    WalletIcon,
    ChevronIcon,
    QuestionCircleIcon,
    MoreIcon,
    Sidebar,
    SendRecieveBuy,
    BuyVip,
    OperationHistory,
    CashOut,
    Collections,
    Invited,
    Income,
    Promocode,
    EarnMore,
    TopTen,
  },
  data() {
    return {
      showWalletDropdown: false,
      money: '420 €',
      status: 'Продюсер',
      coins: [
        { name: 'ARRcoin', abbr: 'ARC', amount: 1.9902 },
        { name: 'Ethereum', abbr: 'ETH', amount: 0.4 },
        { name: 'Bitcoin', abbr: 'BTC', amount: 0.02 },
      ],
    };
  },
  methods: {
    toggleWalletDropdown() {
      this.showWalletDropdown = !this.showWalletDropdown;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';

.wallet {
  max-width: calc(1024px + 64px);
  margin: auto;
  display: flex;
  flex-wrap: wrap;

  .sidebar-mobile {
    display: none;
  }

  > * {
    margin: 0 16px;
  }

  .skirt {
    position: absolute !important;
    background: $clr-05;
    height: 200px;
    width: 100%;
    top: -100px;
    z-index: 5;
  }

  .main-card {
    z-index: 5;
    background: $grad-brand;
    color: white;

    * {
      color: inherit;
    }

    .main {
      font: $font-h-800;
      margin: 24px 0;
      justify-content: space-between;

      .icon-circle {
        border: 1px solid $clr-border-light;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          margin-right: 0;
          transform: translateY(2px);
          transition: transform 1s;

          &.upsidedown {
            transform: rotateX(180deg) translateY(0);
          }
        }
      }
    }

    hr {
      margin-left: 8px;
      color: white;
      opacity: .2;
    }

    .status {
      font: $font-rn-400;

      svg {
        margin-left: 16px;
        opacity: .6;
      }
    }
  }

  .dropdown {
    margin-top: -32px;
    padding-top: 48px;

    .coin {
      display: flex;
      justify-content: space-between;

      .name {
        font: $font-rw-400;
        color: $clr-text-secondary;
      }

      svg {
        margin-right: 0;
        margin-left: 13px;
        color: $clr-text-secondary;
        cursor: pointer;
        height: 20px;
      }
    }
  }

  .sidebar {
    position: relative;
    transform: none;
    left: 0;
  }

  .sidebar-wrap  {
    position: relative;
    left: 0;
    transform: translateX(0);

    > * {
      transition: transform 1s;
      position: relative;
    }

    .slide-enter, .slide-leave-to {
      transform: translateY(-100%);
    }

    .slide-leave-active {
      position: absolute;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .wallet {
    padding-bottom: 126px;

    .sidebar {
      display: none;
    }

    .sidebar-mobile {
      display: block;
    }
  }
}
</style>
