<template>
  <div class="card v-center">
    <ListIcon /> {{ $t('wallet.operation_history') }}
  </div>
</template>

<script>
import ListIcon from '@/components/icons/List.vue';

export default {
  components: {
    ListIcon,
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";

svg {
  color: $clr-text-trietary;
}
</style>
