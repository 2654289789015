<template>
  <div class="card">
    <h2 class="v-center">
      <GroupIcon /> {{ $t("wallet.invited") }}
    </h2>
    <hr>
    <div class="body v-center">
      <div class="v-center">
        <h1>
          32
        </h1>
        <span>{{ $t("wallet.people") }}</span>
        <ChevronIcon />
      </div>
      <div class="highlight">
        +2 в этом месяце
      </div>
    </div>
  </div>
</template>

<script>
import ChevronIcon from '@/components/icons/Chevron.vue';
import GroupIcon from '@/components/icons/Group.vue';

export default {
  components: {
    GroupIcon,
    ChevronIcon,
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";

.body {
  justify-content: space-between;
  font: $font-rn-300;

  h1 {
    display: inline-block;
  }

  svg {
    margin-left: 8px;
    transform: rotateZ(-90deg);
    width: 12px;
    height: 12px;
  }
}

svg {
  color: $clr-text-trietary;
}
</style>
