<template>
  <div class="card v-center">
    <ArrowCircleIcon /> {{ $t('wallet.cash_out') }}
  </div>
</template>

<script>
import ArrowCircleIcon from '@/components/icons/ArrowCircle.vue';

export default {
  components: {
    ArrowCircleIcon,
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";

svg {
  color: $clr-text-trietary;
}
</style>
