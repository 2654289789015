<template>
  <div class="person">
    <div class="avatar-container">
      <div
        class="position"
        :class="{
          gold: position===1,
          silver: position===2,
          bronse: position===3,
        }"
      >
        {{ position }}
      </div>
      <img
        class="avatar supel"
        :src="person.avatar"
      >
    </div>
    <div class="text">
      <span class="name">{{ person.name }}</span>
      <span class="username">{{ person.username }}</span>
      <span class="income">
        {{ incomeCaps }}<span
          v-if="incomeSmall>0"
          class="small"
        >
          ,{{ incomeSmall }}
        </span>
        <span class="small">
          €
        </span>
      </span>
      <StonksIcon />
    </div>
  </div>
</template>

<script>
import StonksIcon from '@/components/icons/Stonks.vue';

export default {
  components: {
    StonksIcon,
  },
  props: {
    person: {
      type: Object,
      required: true,
    },
    position: {
      type: Number,
      required: true,
    },
  },
  computed: {
    // Will show a fraction lower if ends with 3 because of computer math error
    // Use strings instead?
    incomeCaps() {
      return Math.floor(this.person.income);
    },
    incomeSmall() {
      const frac = String(this.person.income % 1);
      return frac.substring(2, 4);
    },
  },
};
</script>

<style lang="scss" scoped>
$clr-gold: #FFEFB7;
$clr-silver: #DDEAEC;
$clr-bronse: #FFE7CA;

.person {
  display: flex;
  margin-bottom: 20px;

  .avatar-container {
    position: relative;
    margin-right: 16px;

    .avatar {
      min-width: 40px;
      height: 40px;
    }

    .position {
      z-index: 1;
      position: absolute;
      left: -14px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: $clr-10;

      &.gold {
        background: $clr-gold;
      }

      &.silver {
        background: $clr-silver;
      }

      &.bronse {
        background: $clr-bronse;
      }
    }
  }

  .text {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .name {
      font: $font-rn-400;
    }

    .username {
      font: $font-rn-300;
      color: $clr-text-trietary;
    }

    .income {
      font: $font-sbn-600;

      .small {
        font: $font-sbn-400;
      }
    }

    svg {
      position: absolute;
      top: 0;
      right: 0;
      color: $clr-text-trietary;
    }
  }
}
</style>
