<template>
  <div class="card send-recieve flex">
    <div>
      <div class="send">
        <ArrowIcon />
      </div>
      <span>{{ $t("wallet.send") }}</span>
    </div>
    <div>
      <div class="recieve">
        <ArrowIcon />
      </div>
      <span>{{ $t("wallet.recieve") }}</span>
    </div>
    <div>
      <div class="buy">
        <ArrowsIcon />
      </div>
      <span>{{ $t("wallet.buy") }}</span>
    </div>
  </div>
</template>

<script>
import ArrowIcon from '@/components/icons/Arrow.vue';
import ArrowsIcon from '@/components/icons/Arrows.vue';

export default {
  components: {
    ArrowIcon,
    ArrowsIcon,
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";

.send-recieve {
  justify-content: space-between;

  > * {
    width: 48px;
    display: flex;
    flex-direction: column;

    span {
      max-width: 48px;
      display: flex;
      justify-content: center;
      color: $clr-text-secondary;
      font: $font-rw-300;
    }
  }

  .send,
  .recieve,
  .buy {
    background: $clr-10;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin: 0;
    }
  }

  .send {
    transform: rotateZ(135deg);
  }

  .recieve {
    transform: rotateZ(-45deg);
  }
}
</style>
